.ion-toolbar {
  --min-height: unset;
  // --border-width: 0px !important;

  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.ion-toolbar__wrapper {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  padding-left: 0px;
  background: var(--header-background);

  &.main {
    height: 61px;
    position: relative;
  }

  &.one-click {
    background: var(--negative);
    color: var(--white);
    justify-content: flex-start;
    padding: 10px 15px;
    position: relative;

    .toolbar-toggle {
      --handle-box-shadow: inset 0px 0px 0px 2px var(--white);
      border: 0;
      // --handle-box-shadow: inset 0px 0px 0px 2px #fff;
      // position: absolute;
      // left: 15px;
      // --handle-width: 19px;

    }
  }

  .one-click-text {
    font-size: 10px;
    margin: 0 auto;
    color: var(--white);
    border-bottom: 1px solid var(--white);
  }

  &.toolbar__tabs {
    flex-flow: row nowrap;
    position: relative;
    padding: 0;
  }

  .toolbar__tabs {
    &__bar {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      width: 100%;
      background: transparent;

      &__tab {
        background: transparent;
        position: relative;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 13px;
        cursor: pointer;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        color: var(--event-tab-color);
        // border-bottom: var(--event-tab-border-bottom);
        border-bottom: 3px solid var(--header-background);

        &.active {
          border-bottom-color: var(--event-tab-active-border-bottom-color);
          color: var(--event-tab-active-color);
        }
      }
    }
  }

}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0)
  }
}

.notification {
  padding: 10px 0;
  font-size: 14px;
  color: var(--header-secondary-color);
  background: var(--event-info-background-color);

  &__container {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    &__marquee {
      position: relative;
      width: auto;
      display: flex;
      white-space: nowrap;
      flex-wrap: nowrap;
      padding-left: 600px;
      animation: marquee 60.012s infinite linear;
      will-change: transform
    }

    &__message {
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      display: flex;
      flex-wrap: nowrap;

      &:not(:last-of-type) {
        border-right: 1px solid var(--header-secondary-color);
      }
    }
  }

  &__close {
    flex: 1 1;
    margin-left: auto;
    padding: 0 16px;
    background: var(--event-info-background-color);
    cursor: pointer;
  }
}

.menu-icon {
  width: 19px;
  height: 12.83px;
  position: relative;
  fill: var(--icon-color-in-buttons);
}

// .menu-icon.go-back-icon:before {
//   content: "";
//   width: 19px;
//   height: 13px;
//   position: absolute;
//   background-color: var(--icon-color-in-buttons);
//   mask-image: url("../../assets/images/goback.svg");
//   mask-size: cover;
//   mask-position: center;
//   mask-repeat: no-repeat;
//   top: 0;
//   left: 0;
// }

// .menu-icon:before {
//   content: "";
//   width: 19px;
//   height: 13px;
//   position: absolute;
//   background-color: var(--icon-color-in-buttons);
//   mask-image: url("../../assets/images/burger-menu.svg");
//   mask-size: cover;
//   mask-position: center;
//   mask-repeat: no-repeat;
//   top: 0;
//   left: 0;
// }


.toolbar-logo {
  display: inline-block;
  background: left center no-repeat var(--logo-bg-img);
  width: var(--logo-width);
  height: var(--logo-height);
  vertical-align: middle;
  background-size: contain;
  //margin-left: 20px;
  // width: 120px;
  // height: 37px;
  cursor: pointer;
}

.toolbar-altertitle {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  margin-left: 15px;
}

.user-profile-btn {
  margin: 0 !important;
  --border-radius: 2px;
  height: 40px;
  width: 69px;
  --background: var(--signUp-button-color);
  --color: var(--header-color);
}

.user-profile-btnLog {
  margin: 0 2px 0 2px !important;
  --border-radius: 2px;
  height: 40px;
  width: 69px;
  --background: var(--login-button-color);
  --color: var(--login-button-text);
}
.user-profile-deposit {
  margin: unset;
  margin-right: 3px;
  --border-radius: 2px;
  height: 40px;
  width: 69px;
  --background: var(--login-button-color);
  --color: var(--login-button-text);
}

.user-profile-btn-sign-up {
  margin: 0 2px 0 2px !important;
  --border-radius: 2px;
  height: 40px;
  width: 69px;
  --background: var(--signUp-button-color);
  --color: var(--signUp-button-text);
}

.user-profile-btn__wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;

  width: 100%;
  align-items: center;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;

  span {
    padding-top: 3px;
    color: var(--btn-text-color);
  }
}

.user-profile-btn__icon {
  width: 13px;
  height: 13px;
  position: relative;
}

.user-profile-btn__icon:after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  background-color: var(--btn-text-color);
  mask-image: url("../../assets/images/toolbar-user-icon.svg");
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  top: 0;
  left: 0;
}

.search-icon {
  width: 20px;
  height: 19px;
  position: relative;
}

.search-icon:after {
  content: "";
  width: 19px;
  height: 19px;
  position: absolute;
  background-color: var(--icon-color-in-buttons);
  mask-image: url("../../assets/images/toolbar-search-icon.svg");
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  top: 0;
  left: 0;
}

.ion-toolbar__left {
  display: flex;
  align-items: center;

  ion-button+ion-img {
    padding-left: 16px;
  }

  ion-button {
    margin: 0;
    padding-right: 20px;
    padding-left:15px;
    --background: transparent;
  }
}

.ion-toolbar__right {
  display: flex;
  align-items: center;

  ion-img+ion-button {
    margin-left: 16px !important;
  }
}

.ion-toolbar__search {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--header-background);
  left: 40px;
  top: -100%;

  display: flex;
  align-items: center;
  flex: 1 1;

  ion-img+ion-button {
    margin-left: 16px !important;
  }

  &__input {
    margin-left: 25px;
    // background-color: transparent !important;
    background: var(--ion-toolbar-background);
    color: var(--white);

    &::placeholder {
      color: var(--white);
    }

    // text-decoration: underline;
    --placeholder-opacity: 1;
    outline: none;
    border: none;


    &.changed {
      // text-decoration: underline;
    }
  }

  .closeCross {
    position: absolute;
    // top: -37px;
    // right: 15px;
    top: 20px;
    right: 50px;
    z-index: 10;
    height: 18px;
    width: 18px;
  }
}

.menu-btn {
  --background-hover: none;
  --background-focused: none;
}

.loginSignup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-icon {
    //img{
    width: 12px;
    height: 12px;
    position: relative;
    //}
  }

  &-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize !important;
    color: var(--btn-text-color);
  }
}

.loginSignup-icon.login:before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  background-color: var(--icon-color-in-buttons);
  mask-image: var(--log-in-svg-icon);
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  top: 0;
  left: 0;
}

.loginSignup-icon.sign-up:before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  background-color: var(--icon-color-in-buttons);
  mask-image: var(--sign-up-svg-icon);
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  top: 0;
  left: 0;
}

.Search {

  &__results {
    margin: 2px;
    display: flex;
    flex-flow: column nowrap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    // align-items: center;
    color: #000000;

    // background-color: transparent;
    &__item {
      border: 1px solid transparent;

      &__type {
        font-size: 12px;
        align-self: center;
      }
    }
  }

  &__empty {
    display: flex;
    // margin: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    // align-items: center;
    color: #000000;
    background: #fff;
    // padding: 20px;
  }
}

.searchValues {
  width: 100%;
  height: auto;
  background: #fff;
  position: absolute;
}

// .closeCross {
//   position: absolute;
//   // top: -37px;
//   // right: 15px;
//   top: 20px;
//   right: 50px;
//   z-index: 10;
//   height: 18px;
//   width: 18px;
// }

.searchShadow {
  position: absolute;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
}

@media (max-width: 360px ) {
  .toolbar-logo {
    //margin-left: 10px;
  }
  .ion-toolbar__left {
    ion-button {
      padding-right: 0px;
    }
  }
  .ion-toolbar__right ion-img + ion-button {
    margin-left: 10px !important;
  }
  .user-profile-btn-sign-up,
  .user-profile-deposit,
  .user-profile-btnLog,
  .user-profile-btn {
    height: 35px;
    width: 60px;
  }
}
@media (max-width: 330px ) {
  .ion-toolbar__right ion-img + ion-button {
    margin-left: 5px !important;
  }
  .user-profile-btn-sign-up,
  .user-profile-deposit,
  .user-profile-btnLog,
  .user-profile-btn {
    height: 35px;
    width: 52px;
    font-size: 11px;
  }
}
